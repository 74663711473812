import React, { useState, useEffect } from 'react';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch posts using Cloudflare function
    const fetchPosts = async () => {
      try {
        setLoading(true);
        
        // Call our Cloudflare function
        const response = await fetch('/get-newsletter-posts', {
          method: 'GET'
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch posts: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        
        if (!data.success || !data.posts || !Array.isArray(data.posts)) {
          throw new Error('Invalid response format from newsletter posts function');
        }
        
        // Process the posts data with better date handling
        const parsedPosts = data.posts.map(post => {
          // Improved date parsing with validation
          let postDate;
          try {
            // Attempt to parse the date
            postDate = new Date(post.date);
            
            // Check if date is valid (not NaN) and not the epoch start (1970)
            if (isNaN(postDate) || postDate.getFullYear() < 2000) {
              // Fallback to today's date if invalid
              console.warn(`Invalid date detected for post ${post.id}: ${post.date}`);
              postDate = new Date();
            }
          } catch (err) {
            console.error(`Error parsing date for post ${post.id}:`, err);
            postDate = new Date(); // Fallback to today's date
          }
          
          return {
            ...post,
            date: postDate
          };
        });
        
        setPosts(parsedPosts);
      } catch (err) {
        console.error('Error fetching newsletter posts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Using Cloudflare Function API
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setFeedback('');
    
    try {
      // Call your Cloudflare Function endpoint
      const response = await fetch('/newsletter-subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email
        })
      });
      
      console.log('Response status:', response.status);
      
      // Check content type to avoid parsing HTML as JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        // It's JSON, safe to parse
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data?.message || `Error ${response.status}: ${response.statusText || 'Unknown error'}`);
        }
        
        // Handle successful subscription
        setFeedback('✅ Successfully subscribed to the newsletter!');
        setFeedbackColor('green');
        setEmail('');
      } else {
        // Not JSON, handle as text
        const text = await response.text();
        console.error('Received non-JSON response:', text.substring(0, 150) + '...');
        throw new Error(`Received non-JSON response (${response.status})`);
      }
    } catch (err) {
      console.error('Subscription error:', err);
      setFeedback(`❌ ${err.message || 'An error occurred. Please try again.'}`);
      setFeedbackColor('red');
    } finally {
      setSubmitting(false);
    }
  };

  // Format date in a nice readable format
  const formatDate = (date) => {
    try {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    } catch (err) {
      console.error('Error formatting date:', err);
      return 'Just published'; // Fallback text
    }
  };

  return (
    <section id="newsletter" className="about">
      <div className="container">
        <div className="about-grid">
          <div className="about-content">
            <h2>Beyond The Password</h2>
            <p>
              Stay up to date with the latest trends and insights. 
              Subscribe for expert advice on growing and protecting your business. We never share or sell your data.
            </p>
            
            <div className="newsletter-subscription">
              <div className="form-group">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    style={{ marginBottom: '15px' }}
                  />
                  <button 
                    type="submit" 
                    className="submit-button"
                    disabled={submitting}
                  >
                    {submitting ? 'Subscribing...' : 'Subscribe'}
                  </button>
                  {feedback && (
                    <div className="form-feedback" style={{ marginTop: '15px', fontWeight: 'bold', color: feedbackColor }}>
                      {feedback}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          
          <div className="newsletter-posts">
            <h3>Latest Pulse Check</h3>
            
            {loading && <p>Loading latest insights...</p>}
            {error && <p>Unable to load newsletter posts. Please check back later.</p>}
            
            {!loading && !error && posts.length > 0 ? (
              <ul className="posts-list" style={{ 
                listStyle: 'none',
                padding: 0,
                margin: 0
              }}>
                {posts.map((post) => (
                  <li key={post.id} className="post-item" style={{
                    borderLeft: '3px solid #0077B6',
                    padding: '15px',
                    marginBottom: '20px',
                    borderRadius: '5px',
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                  }}>
                    <h4 style={{
                      margin: '0 0 10px 0',
                      color: '#333',
                      fontSize: '18px'
                    }}>{post.title}</h4>
                    <p className="post-date" style={{
                      color: '#666',
                      fontSize: '14px',
                      margin: '0 0 10px 0',
                      fontStyle: 'italic'
                    }}>{formatDate(post.date)}</p>
                    <p className="post-excerpt" style={{
                      margin: '0 0 15px 0',
                      color: '#444',
                      lineHeight: '1.5'
                    }}>{post.description}</p>
                    <a 
                      href={post.url} 
                      className="read-more" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{
                        color: '#0077B6',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        transition: 'color 0.2s ease'
                      }}
                    >
                      Read more →
                    </a>
                  </li>
                ))}
              </ul>
            ) : !loading && !error ? (
              <p>No posts available at the moment.</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;