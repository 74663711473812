import React from 'react';

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <h2>Our Services</h2>
        <p>
          At Eli & Rett, we do things differently:<br />
          <b>We create solutions just for you:</b> not generic templates that don't fit your specific situation.<br />
          <b>We work with you as partners:</b> not just advisors who tell you what to do without getting involved.<br />
          <b>We focus on what actually changes:</b> not just fancy reports that sit on a shelf.<br />
          When you work with us, you get a team that understands your unique challenges, works alongside you,
          and delivers results you can actually see and measure.
        </p>
        <div className="services-grid">
          <div className="service-card">
            <div className="service-icon">🔒</div>
            <h3>M&A Due Diligence</h3>
            <p>
            We provide comprehensive cybersecurity due diligence assessments for mergers and acquisitions, identifying digital risks, compliance gaps, and security vulnerabilities that could impact transaction value or create post-merger liabilities, while helping clients establish efficient M&A processes and secure information sharing infrastructure to facilitate seamless, confidential transactions.
            </p>
          </div>
          <div className="service-card">
            <div className="service-icon">🛡️</div>
            <h3>Security Assessment</h3>
            <p>
              Comprehensive evaluation of your security posture, including gap analysis, compliance review, and risk assessment
              to identify areas for improvement in your security program.
            </p>
          </div>
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h3>Security & Tech Strategy</h3>
            <p>
              Develop a holistic security and tech strategy aligned with your business objectives, including roadmaps, policies,
              and governance frameworks to mature your tech program.
            </p>
          </div>
          <div className="service-card">
            <div className="service-icon">👥</div>
            <h3>Security Training</h3>
            <p>
              Build a security-aware culture with customized training programs for employees at all levels, including executive
              awareness, technical training, with risk metrics.
            </p>
          </div>
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h3>Application Security</h3>
            <p>
              Advisory services to help your organization build and maintain a robust application security program, including secure
              development practices, architecture, and security testing.
            </p>
          </div>
          <div className="service-card">
            <div className="service-icon">🔍</div>
            <h3>E-Discovery Services</h3>
            <p>
              Specialized digital forensics and e-discovery services to help your organization identify, collect, and preserve
              electronically stored information for legal and compliance purposes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
