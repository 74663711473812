import React from 'react';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="about-grid">
          <div className="about-content">
            <h2>About Eli & Rett</h2>
            <p>
              Founded by industry veterans with over 25 years of experience, Eli & Rett was established with a singular mission:
              to make security and tech expertise to non-profits, small and medium-sized businesses accessible.
            </p>
            <p>
              We understand that cybersecurity is not just about technology, it's about protecting your business, your reputation,
              and your customers' trust. Our consultants bring experience from diverse backgrounds including media & entertainment,
              consumer brands, non-profits, government, education, and technology sectors.
            </p>
            <p>
              In today's innovative landscape, we're reinventing consultancy by replacing cookie-cutter solutions with tailored strategies
              that evolve through direct partnership and deliver measurable impact and outcomes, not just polished presentations.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/about.jpg" alt="Eli & Rett team" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
