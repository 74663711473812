import React from 'react';

const Header = ({ toggleTheme, theme }) => {
  return (
    <header>
      <div className="container header-container">
        <div className="logo">
          <a href="#" className="logo-text">Eli & <span>Rett</span></a>
        </div>
        <nav>
          <ul>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#clients">Who We Serve</a></li>
            <li><a href="#contact">Contact</a></li>
            <li>
              <div className="theme-toggle-wrapper">
                <input 
                  type="checkbox" 
                  id="theme-toggle-checkbox" 
                  className="theme-toggle-input" 
                  onChange={toggleTheme}
                  checked={theme === 'dark'}
                />
                <label htmlFor="theme-toggle-checkbox" className="theme-toggle-label">
                  <svg className="sun-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="5"></circle>
                    <line x1="12" y1="1" x2="12" y2="3"></line>
                    <line x1="12" y1="21" x2="12" y2="23"></line>
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                    <line x1="1" y1="12" x2="3" y2="12"></line>
                    <line x1="21" y1="12" x2="23" y2="12"></line>
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                  </svg>
                  <svg className="moon-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                  </svg>
                  <span className="toggle-circle"></span>
                </label>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;