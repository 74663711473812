import React from 'react';

const Clients = () => {
  return (
    <section id="clients" className="clients">
      <div className="container">
        <h2>Who We Serve</h2>
        <p>
          We specialize in providing pragmatic cybersecurity and tech services tailored specifically for:
        </p>
        <div className="expertise-grid">
          <div className="expertise-item">
            <div className="expertise-icon">🚀</div>
            <h3>Startups</h3>
            <p>
              Security solutions designed for early-stage companies looking to build security into their foundation without overwhelming resources or budgets.
            </p>
          </div>
          <div className="expertise-item">
            <div className="expertise-icon">🏢</div>
            <h3>Small Businesses</h3>
            <p>
              Practical, cost-effective security and IT programs that protect critical assets while accommodating the unique constraints of small business operations.
            </p>
          </div>
          <div className="expertise-item">
            <div className="expertise-icon">📈</div>
            <h3>Medium Enterprises</h3>
            <p>
              Scalable security and tech strategies that grow with your business, helping you establish mature security practices as your organization expands.
            </p>
          </div>
          <div className="expertise-item">
            <div className="expertise-icon">💼</div>
            <h3>Professional Services</h3>
            <p>
              Specialized security solutions for law firms, financial services, healthcare providers, and other professional service organizations with unique data protection needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
