import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-grid">
          <div className="footer-column">
            <h3>Eli & Rett</h3>
            <p>
            We're a modern cybersecurity and tech consultancy firm that delivers practical solutions.
            </p>
          </div>
          <div className="footer-column">
            <h3>Services</h3>
            <ul className="footer-links">
              <li><a href="#services">Penetration Testing</a></li>
              <li><a href="#services">Security Assessment</a></li>
              <li><a href="#services">Application Security</a></li>
              <li><a href="#services">Security Strategy</a></li>
              <li><a href="#services">E-Discovery Services</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Our Office</h3>
            <p>
              17875 Von Karman Ave.<br />
              Suite 150<br />
              Irvine, CA 92614
            </p>
          </div>
        </div>
        <div className="copyright">
          &copy; 2024 Eli & Rett. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
