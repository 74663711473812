import React, { useEffect, useRef } from 'react';

const Parallax = () => {
  const containerRef = useRef(null);
  const parallaxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && parallaxRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        if (containerRect.top < window.innerHeight && containerRect.bottom > 0) {
          const scrollPosition = window.pageYOffset;
          const containerPosition = containerRef.current.offsetTop;
          const relativePosition = scrollPosition - containerPosition + window.innerHeight;
          const translateY = relativePosition * 0.15;
          parallaxRef.current.style.transform = `translateY(${translateY}px)`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Call it initially in case the element is in view already
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="parallax-container" ref={containerRef}>
      <div
        className="parallax-bg"
        ref={parallaxRef}
        style={{ backgroundImage: "url('/images/parallax.jpg')" }}
      ></div>
    </div>
  );
};

export default Parallax;
