import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [feedback, setFeedback] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setFeedback('');

    try {
      const response = await fetch('/submit-form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server responded with ${response.status}: ${errorText}`);
      }

      // Read the response as text to ensure it's not empty.
      const responseText = await response.text();
      if (!responseText) {
        throw new Error('Empty response');
      }

      const result = JSON.parse(responseText);

      if (result.success) {
        setFeedback('✅ Your message was sent successfully!');
        setFeedbackColor('green');
        setFormData({
          name: '',
          email: '',
          company: '',
          message: ''
        });
      } else {
        setFeedback('⚠️ Something went wrong. Please try again.');
        setFeedbackColor('darkorange');
        console.error('Error:', result);
      }
    } catch (error) {
      setFeedback('❌ Unable to submit form. Check your connection or try again later.');
      setFeedbackColor('red');
      console.error('Submission error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <h2>Contact Us</h2>
        <p>
          We're here to help you break down barriers and achieve meaningful outcomes that drive real value for your organization.
        </p>
        <div className="contact-form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                placeholder="Your Company"
                value={formData.company}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                placeholder="How can we help you?"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button" disabled={submitting}>
              {submitting ? 'Sending...' : 'Send Message'}
            </button>
            {feedback && (
              <div className="form-feedback" style={{ marginTop: '15px', fontWeight: 'bold', color: feedbackColor }}>
                {feedback}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;